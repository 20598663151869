import TomSelect from 'tom-select';


/**
 * Add an message pane to display a hint when a search term is not found
 * in the muliselect labels.
 */
export function addMultiselectMessageElement() {
  if ('content' in document.createElement('template')) {
    const containerElem = document.querySelector("#div_id_categories_filter_selectmulti");

    let notFoundMarkup = `
      <div id="category-not-found" class="alert alert-warning d-none" role="alert">
        No entries found for '<span id="captured-search-term"></span>'
      </div>
    `;

    containerElem.insertAdjacentHTML('beforebegin', notFoundMarkup);
  }
}


function notFoundMessage({state, searchTerm}) {
  const targetElement = document.getElementById("category-not-found")
  document.getElementById("captured-search-term").textContent=searchTerm;

  if (state === "show") {
    targetElement.classList.remove("d-none")
  } else if (state === "hide") {
    targetElement.classList.add("d-none")
  }
}


function filterKeywords(searchTerm) {
  const checkContainerSelector = ".form-check"
  const keywordsContainer = document.getElementById("div_id_categories_filter_selectmulti");

  let keywordItems = keywordsContainer.getElementsByTagName("label");
  let optionContainersArray = Array.from(keywordsContainer.querySelectorAll(checkContainerSelector));
  
  for (selectOption of keywordItems) {
    let optionText = selectOption.innerText.toLowerCase();
    let optionContainer = selectOption.closest(checkContainerSelector);

    if (! optionText.includes(searchTerm) ) {
      // optionContainer.style.display = "none";
      optionContainer.classList.add("d-none");
    } else {
      optionContainer.classList.remove("d-none");
    }
  }

  if ( optionContainersArray.every(element => element.classList.contains("d-none")) ){
    // console.log("no valid entries")
    notFoundMessage({
      state: 'show',
      searchTerm: searchTerm,
    })
  } else {
    notFoundMessage({
      state: 'hide',
      searchTerm: searchTerm,
    })
  }
}


export function monitorMultiselectSearchTerm() {
  const keywordInput = document.getElementById("keyword-search-input");
  // console.info("keywordInput: ", keywordInput);
  let searchTerm = "";

  keywordInput.addEventListener("keyup", function (e) {
    searchTerm = this.value
    if (searchTerm.length > 1) {
      searchTerm = searchTerm.toLowerCase();
      // console.info("searchTerm: ", searchTerm);
    } else {
      searchTerm = ""
    }
    filterKeywords(searchTerm)
  })
}

/**
 * Move selected categories at the beginning of the categories select list.
 * Reverse the NodeList as the items get added from top to bottom to the 
 * parent element.
 * Implemented an index for the for-of loop to catch the first iteration.
 * See: https://flaviocopes.com/how-to-get-index-in-for-of-loop/
 */
export function moveSelectedOptionsToTop(){
  let selectContainer = document.querySelector("#div_id_categories_filter_selectmulti div");
  let checkedCategories = Array.from(document.querySelectorAll("#div_id_categories_filter_selectmulti input[type='checkbox']:checked"));

  checkedCategories.reverse()

  for ( let [index, item] of checkedCategories.entries() ) {
    if (index == 0) {
      let hrNode = document.createElement("hr")
      selectContainer.insertBefore(hrNode, selectContainer.childNodes[0]);
    }      
    // last iteration: if (checkedCategories.length == index +1) {
    selectContainer.insertBefore(item.parentElement, selectContainer.childNodes[0]);
  }
}

/**
 * Initialize our TomSelect-enriched multiselect fields.
 */
function initTomSelects(){
  document.querySelectorAll('.is-tom-select').forEach((el)=>{
    let tomSelectconfig = {
        plugins: ['remove_button'],
        create: false,
        hidePlaceholder: true,
        maxOptions: null,
    };
    new TomSelect(el, tomSelectconfig);
  });
}


/**
 * Handle the search button: Set it' state to disabled if currently there
 * are no changes in the form.
 */
function getFormState(formElem){
  let formData = new FormData(formElem);
  let formEntries = [...formData.entries()];
  // console.log("formEntries: ", formEntries);
  let formEntriesJson = JSON.stringify(formEntries)
  // console.log("formEntriesJson: ", formEntriesJson);
  return formEntriesJson;
}


function compareFormState(searchButton, searchForm, initialFormState){
  const changedFormState = getFormState(searchForm);
  // console.info("initialFormState: ", initialFormState)
  // console.info("changedFormState: ", changedFormState);

  if (changedFormState === initialFormState) {
    searchButton.disabled = true;
  } else {
    searchButton.disabled = false;
  }
}


function detectFormChanges(searchButton, searchForm, initialFormState){
  searchForm.addEventListener('input', (event) => {
    // console.info("Form changed! event: ", event);
    compareFormState(searchButton, searchForm, initialFormState);
  });
}

function setInitialSearchButtonState(searchButton, searchForm, initialFormState){
    // console.info("Set initial search button state");
    compareFormState(searchButton, searchForm, initialFormState);
}

export function initForm(){

  addMultiselectMessageElement();
  monitorMultiselectSearchTerm();
  moveSelectedOptionsToTop();

  initTomSelects();

  const searchForm = document.getElementById('filter-form');
  const searchButton = document.getElementById("search-button");
  const initialFormState = getFormState(searchForm);

  setInitialSearchButtonState(searchButton, searchForm, initialFormState);
  detectFormChanges(searchButton, searchForm, initialFormState);
}
