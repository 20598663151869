import { Toast } from 'bootstrap';

export function copyToClipboard(text) {
  navigator.clipboard.writeText(text).then(function() {
    // Create toast container if it doesn't exist
    let toastContainer = document.querySelector('.toast-container');
    if (!toastContainer) {
      toastContainer = document.createElement('div');
      toastContainer.className = 'toast-container position-fixed bottom-0 end-0 p-3';
      document.body.appendChild(toastContainer);
    }

    // Create toast element
    const toastEl = document.createElement('div');
    toastEl.setAttribute('data-bs-config', '{"autohide":false}');
    toastEl.className = 'toast text-bg-primary';
    toastEl.role = 'alert';
    toastEl.ariaLive = 'assertive';
    toastEl.ariaAtomic = 'true';
    toastContainer.className = 'toast-container position-fixed top-0 start-50 translate-middle-x p-3';
    // Remove any existing toasts
    const existingToasts = toastContainer.querySelectorAll('.toast');
    existingToasts.forEach(toast => toast.remove());

    toastEl.innerHTML = `
      <div class="toast-header">
        <strong class="me-auto">Link copied to your clipboard</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body">
        Share it on your favorite channel:
        <br>
        <strong><a class="text-white" href="${text}" target="_blank">${text}</a></strong>
      </div>
    `;

    // Append toast to container
    toastContainer.appendChild(toastEl);

    // Initialize and show toast
    const toast = new Toast(toastEl);
    toast.show();

    // Remove toast after it hides
    toastEl.addEventListener('hidden.bs.toast', () => {
      toastEl.remove();
    });
  }, function(err) {
    console.error('Could not copy text: ', err);
  });
}
